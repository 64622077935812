import React from 'react'
import Chennai from './Chennai'
import Coimbatore from './Coimbatore'
import Bangalore from './Bangalore'
import Madurai from './Madurai'
import Trichy from './Trichy'
import Salem from './Salem'
import Tirunelveli from './Tirunelveli'
import Kanyakumari from './Kanyakumari'
import Rameshwaram from './Rameshwaram'
import Footerbottom from './Footerbottom'

const Footer = () => {
  return (
    <><div className='container-footer'>
      <div className="footer">
        <Chennai />
        <Coimbatore />
        <Bangalore />
        <Madurai />
        <Trichy />
        <Salem />
        <Tirunelveli />
        <Kanyakumari />
        <Rameshwaram />
      </div>
      <div>
      <Footerbottom /> </div>
    </div>
 </> )
}

export default Footer
