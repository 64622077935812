import React from 'react'
const Places = (props) => {
  return (
    <div className='container-image'>
     <img src={props.image} className='places-img' />
     <div className='place-content'>
        <h4 className='place-content-h4'>{props.place}</h4>
        <p className='place-content-p'>One way Taxi</p>
        <span className='place-content-span'>Rs. {props.rupees}</span>
     </div>
    </div>
  )
}

export default Places
