import React from 'react'
import Card from './Card'
import car1 from '../../assert/tarrifcar1.png'
import car2 from '../../assert/terrifcar2.png'
import car3 from '../../assert/terrifcar3.png'

const Tarrif = () => {
  return (
    <div className="container-tarrif" id='tarrif'>
       <div className='tarrif-header'>
        <span className="tarrif-span">TAXI RATES</span>
        <h1 className="tarrif-h1">Our Pricing</h1>
       </div>
       <div  className='container-tarrif-card'>
       <Card className="carimage2" image={car3} name={'SEDAN'}     rs="14" rupee="13"/>

       <Card className="carimage"  image={car1} name={'SUV'}       rs="19" rupee="17"/>

       <Card className="carimage1 " image={car2} name={'ASSURED INNOVA'}     rs="20" rupee="18"/>
       <Card className="carimage1" image={car2} name={'INNOVA CRYSTA'}     rs="20" rupee="18"/>


       </div>
    </div>
  )
}

export default Tarrif
