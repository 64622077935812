import React from 'react'

const Coimbatore = () => {
  return (
    <div>
    <h3  id="footer-h3">Coimbatore</h3>
    <div>
<p id='footer-para'>Coimbatore to Chennai taxi</p>
<p id='footer-para'>Coimbatore to Chennai taxi</p>
<p id='footer-para'>Coimbatore to Bangalore taxi</p>
<p id='footer-para'>Coimbatore to Kochi taxi</p>
<p id='footer-para'>Coimbatore to Madurai taxi</p>
<p id='footer-para'>Coimbatore to Salem taxi</p>
<p id='footer-para'>Coimbatore to Trichy taxi</p>
<p id='footer-para'>Coimbatore to Pondicherry taxi</p>
<p id='footer-para'>Coimbatore to Tirunelveli taxi</p>
<p id='footer-para'>Coimbatore to Neyveli taxi</p>
<p id='footer-para'>Coimbatore to Rameshwaram taxi</p>
</div>
    </div>
  )
  
}

export default Coimbatore
