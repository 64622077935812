import React from 'react'

const Salem = () => {
  return (
    <div>
    <h3 id="footer-h3">Salem</h3>
    <div>
<p id='footer-para'>Salem to Chennai taxi</p>
<p id='footer-para'>Salem to Bangalore taxi</p>
<p id='footer-para'>Salem to Thoothukudi taxi</p>
<p id='footer-para'>Salem to Madurai taxi</p>
<p id='footer-para'>Salem to Coimbatore taxi</p>
<p id='footer-para'>Salem to Trichy taxi</p>
<p id='footer-para'>Salem to Pondicherry taxi</p>
<p id='footer-para'>Salem to Tirunelveli taxi</p>
<p id='footer-para'>Salem to Neyveli taxi</p>
<p id='footer-para'>Salem to Rameshwaram taxi</p>
</div>
    </div>
   )
}

export default Salem
