import React, { useState } from 'react';
import OneWay from './OneWay';
import Roundway from './Roundway';

const SwitchOneWayRoundway = () => {
    const [way, setWay] = useState('OneWay'); 
    const [color, setColor] = useState(false); 

    const handleOptionSelect = (isOneWay) => {
        setWay( isOneWay == true ?   'Roundway':'OneWay'); 
        setColor(isOneWay); 
    };

    return (
        <>
            <div className="container-onewayRoundway" id="booking">
                <span
                    onClick={() => handleOptionSelect(false)}
                    className={color ? '' : 'onewayRoundway-label'}
                >
                    One way
                </span>
                
                <span
                    onClick={() => handleOptionSelect(true)}
                    className={!color ? '' : 'onewayRoundway-label'}
                >
                    Round Trip
                </span>
            </div>

            {way === 'OneWay' ? <OneWay /> : <Roundway />}
            
            <div className='booking-bookataxi'>
                <h4 className='booking-bookataxi-h4'>ANYTIME & ANYWHERE! <br />WE'VE GOT YOU</h4>
                <p className='booking-bookataxi-p'>Hire our taxi for Hassle free Pickup and Drop of your choice.</p>
                <a href="#booking" className='booking-bookataxi-linktag'>Book a Taxi</a>
            </div> 
        </>
    );
};

export default SwitchOneWayRoundway;
