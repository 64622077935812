import React, { useEffect, useRef, useState } from 'react'
import { useForm, } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FaTimes} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StandaloneSearchBox  } from '@react-google-maps/api';
import axios from 'axios';
import select_Car from '../../assert/select_car.png'
import seden from '../../assert/sedan.png';
import etios from '../../assert/etios.png';
import suv from '../../assert/suv.png';
import innova from '../../assert/innova.png';


// libraries:['places']

function OneWay() {
  const [selectedDate, setSelectedDate] = useState('');
  const [carList, setCarList] = useState(false);
  const [driverbeta, setDriverBeta] = useState(400);
  const [listCars, setListCars] = useState({ car_amount: 0 });
  const [carReq, setCarReq] = useState(false);
  const [distanceResult, setDistanceResult] = useState({ org_distance: null, org_duration: null });
  const [google, setGoogle] = useState(null);
  const [popup, setPopup] = useState(false);
  const [date,setDate ]= useState('');
  const [popupDis, setPopupDis] = useState(0);
  const [popupDur, setPopupDur] = useState('');
  const [whatsaappMsg, setWhatsaappMsg] = useState('');

  const [popupCar, setPopupCar] = useState('');
  const [popupRate, setPopupRate] = useState(0);
  const [totRate, setTotalRate] = useState(0);
  const [loadingButton, setBoadingButton] = useState(true);


  function openCars() {
    setCarList(!carList);
  }
    const today = new Date().toISOString().split('T')[0];

  const searchBox = useRef(null);
      const formRef = useRef(null);
      function sendWhatsapp() {
        setPopup(false);

        window.open("https://wa.me/+918248944500?text=" + whatsaappMsg);
      
    }


  function handlePlacesChanged(){
    const places = searchBox.current.getPlaces();
    console.log(places);

  }
  function popUpClose() {
    setPopup(false);
}
 const changeDate = (e)=>{
   setDate(e.target.value);
 };
  useEffect(() => {
    if (window.google) {
        setGoogle(window.google);
    }
}, []);

  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const onSubmit = async (data) => {
    setCarReq(false);
    if (listCars.car_amount === "0") {
        setCarReq(true);
    }
    if (listCars.car_amount !== "0" && data.dropoffaddress !== '' && data.fname !== '' && data.phone !== '' 
    && data.pickupaddress !== '' && data.pickup_date !== '' && data.pickup_time !== '') {
      setBoadingButton(true);
      if (data.dropoffaddress === data.pickupaddress) {
          return alert("Please select Different Places");
      }
      if (!google) {
          alert('Google Maps API is not loaded yet.');
          return;
      }
      setPopupCar(listCars.text);

      const distanceMatrixService = new google.maps.DistanceMatrixService();

      const distanceMatrixOptions = {
          origins: [data.pickupaddress],
          destinations: [data.dropoffaddress],
          travelMode: 'DRIVING'
      };
      await distanceMatrixService.getDistanceMatrix(distanceMatrixOptions, (response, status) => {
        if (status === 'OK' && response.destinationAddresses[0].length !== 0 && response.originAddresses[0].length !== 0) {
            if (response.rows[0].elements[0].distance.text || response.rows[0].elements[0].duration.text) {
                var org_distance = response.rows[0].elements[0].distance.text;
                var org_duration = response.rows[0].elements[0].duration.text;
                setPopupDur(org_duration);
                setPopupDis(org_distance);
                setDistanceResult({ org_distance, org_duration });

                if (org_distance != null && org_duration != null) {
                    var one_way_rate = parseInt(org_distance) * parseInt(listCars.car_amount);
                    // console.log(org_distance);
                    // console.log(listCars.car_amount);
                    // console.log(listCars.car_amount);
                    setTotalRate(parseInt(org_distance) * parseInt(listCars.car_amount));
                    if (parseInt(org_distance) <= 130) {
                        one_way_rate  = 130 * parseInt(listCars.car_amount);

                        setTotalRate(130 * parseInt(listCars.car_amount));
                    }
                    setPopupRate((parseInt(one_way_rate) + driverbeta))
                    var front_url = "https://www.a2zonewaytaxi.in/";

                    setWhatsaappMsg(`Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : ${data.fname},%0A%0A Mobile Number  : +91 ${data.phone} ,%0A%0APickup Location  : ${data.pickupaddress} ,%0A%0ADrop Location : ${data.dropoffaddress} + %0A%0ATrip Type: One Way ,%0A%0APickup Date/Time: ${data.date} + " " + ${data.dropdown} ,%0A%0ATotal KM :  ${org_distance}  ,%0A%0ADuration : ${org_duration}  ,%0A%0ARate Per KM: ${listCars.car_amount}₹,%0A%0ADriver Beta :${driverbeta}₹  ,%0A%0ARate for Oneway : ${one_way_rate}₹,%0A%0ATotal Trip Fare :${(parseInt(one_way_rate) + driverbeta)}₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +91 8248944500 %0A%0A ${front_url} `)

    var message = `Website Enquiry\n**************\n\n Your Booking Details:\n\n Name:${data.fname} \n\n Mobile Number: +91 ${data.phone} \n\n Pick Up Address: ${data.pickupaddress} \n\n Drop Off Address:${data.dropoffaddress} \n\n Trip Type: One Way  \n\n Pick Up Date/Time:${data.date} ${data.dropdown} \n\n Total Km:${org_distance} \n\n Duration :${org_duration}  \n\n Rate Per KM : ${listCars.car_amount}₹  \n\n Driver Beta :${driverbeta}₹ \n\nRate for Oneway : ${one_way_rate}₹,\n\nTotal Trip Fare :${(parseInt(one_way_rate) + driverbeta)},\n\nToll, parking, permit extra \n\nFor any questions please contact +91 8248944500 \n\n ${front_url}`;
    try{
    const response = axios.post(
      `https://api.telegram.org/bot6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo/sendMessage`,
      {
        chat_id: 1113684027,
        text: message,
                 } );
                 console.log(response);
                if(response)
                 {
                  setListCars({
                                    text: '',
                                    imageSrc: '',
                                    car_amount: 0
                                })
                                setBoadingButton(false);
                                formRef.current.reset();
                                setPopup(true);
                  } }
                              
                              catch (error) {
                                setBoadingButton(false);
                                alert('Please try again')
                                console.error('Error sending Telegram message:', error);
                            }
                            // try{
                            //   const response = axios.post(
                            //     `https://api.telegram.org/bot6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo/sendMessage`,
                            //     {
                            //       chat_id: 1113684027,
                            //       text: message,
                            //                } );
                            //               }
                            //               catch (error) {
                            //                 console.error('Error sending Telegram message:', error);
                            //             }
                                      }
            
  else {

    alert("Please Try again!");
}
}
      }
else {
  console.error('Error:', status);
}

});
    
  };}
  const selectCar = (text, imageSrc, car_amount, carDriverBeta) => {
    setListCars({
      text,
      imageSrc,
      car_amount
    });
    setDriverBeta(carDriverBeta);
    setCarList(false);
  };
  return (
    <div className='container-oneway'>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>


        <div class="form-header">
          <div class="input-fname">
            <input type="text" placeholder='First Name'
              className='form-input' name="fname" {...register("fname", { required: "First Name is required" })} />
            {errors?.fname && <span className='form-error'>{errors.fname.message}</span>}</div>
          <div class="input-fname">
            <input type="int" placeholder='Mobile Number' name="phone"
              className='form-input' {...register("phone", { required: "Mobile Number is required"  ,minLength:{value:10,message:"Requied 10 digit"}})} />
            {errors?.phone && <span className='form-error'>{errors.phone.message}</span>}</div>
          <div class="input-pickupaddress">
          <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >

            <input type="text" placeholder='Pick Up Address' name="pickupaddress"
              className='form-input' {...register("pickupaddress", { required: "Pick Up Address" })} />
                            </ StandaloneSearchBox>

            {errors?.pickupaddress && <span className='form-error'>{errors.pickupaddress.message}</span>}</div>
          <div class="input-pickupaddress">
          <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >

            <input type="text" placeholder='Drop Off Address' name="dropoffaddress"
              className='form-input' {...register("dropoffaddress", { required: "Drop Off Address" })} />
              </ StandaloneSearchBox>
           
            {errors?.dropoffaddress && <span className='form-error'>{errors.dropoffaddress.message}</span>}</div>
            <div class="input-date">
            <input type="date"  name="date"
              className='form-input'
              min={today}
              placeholder="Pick Up Date"
               {...register("date", { required: " date" })} />
            {errors?.date && <span className='form-error'>{errors.date.message}</span>}
</div>
           {/* <div class="input-date">
            <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              className='form-input'
              placeholderText="Pick up Date"
              name='pickup_date' />
            
            <input type="hidden" value={date}   name='pickup_date'
              {...register("pickup_date", { required: "Required Date" })} />
             {!selectedDate && errors?.pickup_date && <span className='form-error'>{errors.pickup_date.message}</span>}
          </div>  */}
          <div class="input-dropdown ">
            <select id="pick_up_time " className='form-input'
              name="dropdown" {...register("dropdown", { required: "PickUp Time is required" })}>
              <option value="">Pick Up Time</option>
              <option value="12.00 AM">12.00 AM</option>
              <option value="12.30 AM">12.30 AM</option>
              <option value="1.00 AM">1.00 AM</option>
              <option value="1.30 AM">1.30 AM</option>
              <option value="2.00 AM">2.00 AM</option>
              <option value="2.30 AM">2.30 AM</option>
              <option value="3.00 AM">3.00 AM</option>
              <option value="3.30 AM">3.30 AM</option>
              <option value="4.00 AM">4.00 AM</option>
              <option value="4.30 AM">4.30 AM</option>
              <option value="5.00 AM">5.00 AM</option>
              <option value="5.30 AM">5.30 AM</option>
              <option value="6.00 AM">6.00 AM</option>
              <option value="6.30 AM">6.30 AM</option>
              <option value="7.00 AM">7.00 AM</option>
              <option value="7.30 AM">7.30 AM</option>
              <option value="8.00 AM">8.00 AM</option>
              <option value="8.30 AM">8.30 AM</option>
              <option value="9.00 AM">9.00 AM</option>
              <option value="9.30 AM">9.30 AM</option>
              <option value="10.00 AM"> 10.00 AM</option>
              <option value="10.30 AM">10.30 AM</option>
              <option value="11.00 AM">11.00 AM</option>
              <option value="11.30 AM">11.30 AM</option>
              <option value="12.00 PM">12.00 PM</option>
              <option value="12.30 PM">12.30 PM</option>
              <option value="1.00 PM">1.00 PM</option>
              <option value="1.30 PM">1.30 PM</option>
              <option value="2.00 PM">2.00 PM</option>
              <option value="2.30 PM">2.30 PM</option>
              <option value="3.00 PM">3.00 PM</option>
              <option value="3.30 PM">3.30 PM</option>
              <option value="4.00 PM">4.00 PM</option>
              <option value="4.30 PM">4.30 PM</option>
              <option value="5.00 PM">5.00 PM</option>
              <option value="5.30 PM">5.30 PM</option>
              <option value="6.00 PM">6.00 PM</option>
              <option value="6.30 PM">6.30 PM</option>
              <option value="7.00 PM">7.00 PM</option>
              <option value="7.30 PM">7.30 PM</option>
              <option value="8.00 PM">8.00 PM</option>
              <option value="8.30 PM">8.30 PM</option>
              <option value="9.00 PM">9.00 PM</option>
              <option value="9.30 PM">9.30 PM</option>
              <option value="10.00 PM">10.00 PM</option>
              <option value="10.30 PM">10.30 PM</option>
              <option value="11.00 PM">11.00 PM</option>
              <option value="11.30 PM">11.30 PM</option>

            </select>{errors?.dropdown && <span className='form-error'>{errors.dropdown.message}</span>}
          </div>

        </div>
        <div>
          <input type="hidden" name='car_amount' value={listCars.car_amount !== 0 ? listCars.car_amount : 0} />
          <div className='input-selectButton' onClick={openCars}>
            {listCars.text ? listCars.text : 'Select car Type'}

            <img src={listCars.imageSrc || select_Car} className="select_car_image" alt="car" />
          </div>
          <ul className={`input-ul  ${carList ? '' : 'input-ul-hidden'}`}>
            <li className='input-ul-li' onClick={() => selectCar('SEDAN', seden, 14, 400)}>SEDAN
              <img src={seden} alt="etios" className='input-ul-li-img' /></li>
            < li className='input-ul-li' onClick={() => selectCar('SUV', suv, 19, 400)} >SUV
              <img src={suv} alt="suv" className='input-ul-li-img' /></li>
              < li className='input-ul-li' onClick={() => selectCar(' ASSURED  INNOVA', innova, 20, 400)} >ASSURED  INNOVA
              <img src={innova} alt="innova" className='input-ul-li-img' /></li>
              < li className='input-ul-li' onClick={() => selectCar(' INNOVA CRYSTA', innova, 23, 400)} >INNOVA CRYSTA
              <img src={innova} alt="innova" className='input-ul-li-img' /></li>

          </ul>
          { carReq ? <span className='form-error'>Car is required.</span> : ''}

        </div>
        <div className="form-button">
          <input type='submit' value='GET ESTIMATION' className='form-submitbutton' />
        </div>
      </form>
    {popup ?  <div class="popup-container">
    <div className="popup">
      
             <span className>
             <FaTimes onClick={popUpClose}  className='popupclose'/>
             </span>
             <h2 className='popuph2'>Trip Estimation:<span className='popuprate'>Rs.{popupRate}</span></h2>
             <div>
             <div className='detailspopup'>
               <div className="detaispopup-left">Total Distance:</div>
               <div className="detaispopup-right">                                
               {popupDis}
                  </div>
               <div className="detaispopup-left">Total Duration:</div>
               <div className="detaispopup-right">                               
                {popupDur}
                </div>
               <div className="detaispopup-left">Selected Car Type:</div>
               <div className="detaispopup-right">
               {popupCar}
               </div>
               <div className="detaispopup-left">Driver Allowance:</div>
               <div className="detaispopup-right">Included</div>
               <div className="detaispopup-left">Total Awarness:</div>
               <div className="detaispopup-right">
               Rs. {totRate}
               </div>
               <div className="detaispopup-leftlast">Driver Beta:</div>
               <div className="detaispopup-rightlast">
               Rs. {driverbeta}
               </div>
             </div>
             <div className='popupdivbutton'>
              <button  onClick={sendWhatsapp} className='popupbutton'>Book Now</button>
             </div>
             </div>   </div>  </div>  : ' '}

    </div>
  );
}

export default OneWay
