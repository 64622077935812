import React from 'react'

const Trichy = () => {
  return (
    <div>
    <h3 id="footer-h3">Trichy</h3>
    <div>
<p id='footer-para'>Trichy to Chennai taxi</p>
<p id='footer-para'>Trichy to Bangalore taxi</p>
<p id='footer-para'>Trichy to Thoothukudi taxi</p>
<p id='footer-para'>Trichy to Madurai taxi</p>
<p id='footer-para'>Trichy to Salem taxi</p>
<p id='footer-para'>Trichy to Coimbatore taxi</p>
<p id='footer-para'>Trichy to Pondicherry taxi</p>
<p id='footer-para'>Trichy to Tirunelveli taxi</p>
<p id='footer-para'>Trichy to Neyveli taxi</p>
<p id='footer-para'>Trichy to Rameshwaram taxi</p>
</div>
    </div>
   )
}

export default Trichy
