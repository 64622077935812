import React from 'react'

const Bangalore = () => {
  return (
    <div>
    <h3 id="footer-h3">Bangalore</h3>
    <div>
<p id='footer-para'>Bangalore to Chennai taxi</p>
<p id='footer-para'>Bangalore to Coimbatore taxi</p>
<p id='footer-para'>Bangalore to Kochi taxi</p>
<p id='footer-para'>Bangalore to Madurai taxi</p>
<p id='footer-para'>Bangalore to Salem taxi</p>
<p id='footer-para'>Bangalore to Trichy taxi</p>
<p id='footer-para'>Bangalore to Pondicherry taxi</p>
<p id='footer-para'>Bangalore to Tirunelveli taxi</p>
<p id='footer-para'>Bangalore to Neyveli taxi</p>
<p id='footer-para'>Bangalore to Rameshwaram taxi</p>
</div>
    </div>
  )
}

export default Bangalore
