import React from 'react'

const TaxiService = () => {
  return (
    <div className='container-Taxiservice'>
        
      <span className='taxiservice-span'>Looking for One Way Taxi Service ?</span>
      <button className='taxiservice-button'> 
      <a href="tel:7540057878" >Call Now</a></button>
    </div>
  )
}

export default TaxiService
