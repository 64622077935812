import React from 'react'
import image from '../../assert/A2Z_logo.jpg'
import Outstation from './Outstation'
import Company from './Company'
import Officialinfo from './Officialinfo'
import '../../App.css'
const Footerbottom = () => {
  return (
    <>
    <div class="footer-element">
        <div class="footer-bottom">
          <img src={image} class="footer-image" />
          <p class="footer-bottom-image-para">We are providing Oneway Taxi, Outstation Taxi, and Airport Taxi.
              Hire Oneway Taxi with cheapest price right at your place. Always Available 24/7.
          </p>
      </div><Outstation/>
      <Company/>
      <Officialinfo />
      </div><center className="cent-link">Copyright @ 2024 <a href="https://flydroptaxi.in/">A2 One Way Taxi</a>. All Rights Reserved. 
</center>
</>
  )
}

export default Footerbottom
