import React,{useState} from 'react'
import {FaBars,FaTimes} from 'react-icons/fa';
import './Contact.js'


const Home = () => {
  const [isMobile, setisMobile] = useState(false)
  const openNav = () => {
    setisMobile(true);
};

const closeNav = () => {
  setisMobile(false);
};

  return (

    < div className='home'>

        < ul className={isMobile ? 'home-ul-mobile' : 'home-ul'}>
          <span onClick={openNav} className='navbaropen'><FaBars className='navbaropenicon' /></span>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}><a href="/" className='home-link'>HOME</a></li>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}><a href="#booking" className='home-link'>BOOKING</a></li>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}><a href="#aboutus_sec" className='home-link'>ABOUT</a></li>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}><a href="#tarrif" className='home-link'>TARRIF</a></li>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}><a href="#vehicle" className='home-link'>VEHICLE</a></li>
          <li className={isMobile ? 'home-ul-li-mobile' : 'home-ul-li'}>
            <span onClick={closeNav} className="navbarclose">
              <FaTimes className='navbarcloseicon' /></span>
          </li>
        </ul>

      </div>
    
  )
}

export default Home
