import React from 'react'
import image from '../assert/phone_image.png'
const PhoneImage = () => {
  return (
    <div className='phone-image'>
      <a href="tel:+918248944500"><img src={image} className='phone-image-call' /></a>
    </div>
  )
}

export default PhoneImage
