import React from 'react'
import '../App.css'
import Home from './Home.js'
import Booking from './Booking/Booking.js'
import AboutUs from './Booking/AboutUs.js'
import TaxiService from './TaxiService.js'
import Tarrif from './Tarrif/Tarrif.js'
import Vehicle from './Vehicle.js'

  const Header = () => {
    return (
      <div className="container-header">
        <div className=''><Home /></div>
      <div className="header-booking"><Booking /></div>
      <div className="header-aboutus"><AboutUs /></div>
      <div><Tarrif /></div>
      <div><Vehicle /></div>


<div><TaxiService /></div>
      </div>
    );
  };
  
export default Header;
