import React from 'react'
import  logo from '../assert/A2Z_logo.jpg'
import Contact from './Contact.js'
import { EnvelopeFill,TelephoneFill  } from 'react-bootstrap-icons';
import Button from './Button.js'


const CommonHeader = () => {
  return (
    <div className='container-home'>
      {/* <marquee className="marquee">Service starts @ Rs.13 per for details view our tariff</marquee> */}
      <div className="header">
        <img src={logo} className="carlogo" />
        <div className="header-mail">
          <Contact image={<EnvelopeFill />} Label={"Email Address :"} email={"a2onewaytaxi1@gmail.com"} />
        </div>
        <Contact image={<TelephoneFill />} Label={"Phone Number :"} email={"91 8248944500"} isphone='1' />
        {/* {<Phone image={<TelephoneFill />} Label={"Phone Number :"} Phone={"8220030584"} />  } */}
        <div className="header-button">
        <span>
           <a href="#booking">BOOK A TAXI</a>
        </span>
        </div>
      </div>
      </div>
  )
}

export default CommonHeader
