import React from 'react'

const Tirunelveli = () => {
  return (
    <div>
    <h3 id="footer-h3">Tirunelveli</h3>
    <div>
<p id='footer-para'>Tirunelveli to Chennai taxi</p>
<p id='footer-para'>Tirunelveli to Bangalore taxi</p>
<p id='footer-para'>Tirunelveli to Thoothukudi taxi</p>
<p id='footer-para'>Tirunelveli to Madurai taxi</p>
<p id='footer-para'>Tirunelveli to Salem taxi</p>
<p id='footer-para'>Tirunelveli to Trichy taxi</p>
<p id='footer-para'>Tirunelveli to Coimbatore taxi</p>
<p id='footer-para'>Tirunelveli to Pondicherry taxi</p>
<p id='footer-para'>Tirunelveli to Pondicherry taxi</p>
<p id='footer-para'>Tirunelveli to Rameshwaram taxi</p>
</div>
    </div>
   )
}

export default Tirunelveli
