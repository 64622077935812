import React from 'react'
import { Link } from 'react-router-dom';

const Company = () => {
  return (
    <div class="footer-company">
      <h3>Company</h3>
        <ul class="footer-company-ul">
            <li><a href="#" className='footer-company-ul-link'> Home</a></li>
            <li><a href="#booking" className='footer-company-ul-link'>Booking</a></li>
            <li><a href="#aboutus_sec" className='footer-company-ul-link'>About</a></li>
            <li><a href="#tarrif" className='footer-company-ul-link'>Our Pricing</a></li>
            <li><a href="#vehicle" className='footer-company-ul-link'>Vehicles </a></li>
            <li><Link to="/terms-and-conditions" className='footer-company-ul-link'>Terms And services</Link></li>
            <li><Link to="/privacy-policy" className='footer-company-ul-link'>Privacy Policies</Link></li>
        </ul>
      
    </div>
  )
}

export default Company
