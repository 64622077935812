import React from 'react'
import image from '../assert/whatsapp_image.png'

const WhatsappImage = () => {
  return (
    <div className="whatsapp">
      <a href="https://wa.me/+918248944500"  target='_blank'>
      <img src ={image}  className='whatsapp-image'/>
      </a>
    </div>
  )
}

export default WhatsappImage
