import React from 'react'

const Rameshwaram = () => {
  return (
    <div>
    <h3 id="footer-h3">Rameshwaram</h3>
    <div>
<p id='footer-para'>Rameshwaram to Chennai taxi</p>
<p id='footer-para'>Rameshwaram to Bangalore taxi</p>
<p id='footer-para'>Rameshwaram to Thoothukudi taxi</p>
<p id='footer-para'>Rameshwaram to Madurai taxi</p>
<p id='footer-para'>Rameshwaram to Salem taxi</p>
<p id='footer-para'>Rameshwaram to Trichy taxi</p>
<p id='footer-para'>Rameshwaram to Coimbatore taxi</p>
<p id='footer-para'>Rameshwaram to Pondicherry taxi</p>
<p id='footer-para'>Rameshwaram to Neyveli taxi</p>
<p id='footer-para'> Rameshwaram to Kanyakumari taxi</p>
</div>
    </div>
   )
}

export default Rameshwaram

