import React, { useEffect, useRef, useState } from 'react'
import { useForm, } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StandaloneSearchBox } from '@react-google-maps/api';
import axios from 'axios';
import select_Car from '../../assert/select_car.png'
import seden from '../../assert/sedan.png';
import etios from '../../assert/etios.png';
import suv from '../../assert/suv.png';
import innova from '../../assert/innova.png';


// libraries:['places']

function Roundway() {
  const [selectedDate, setSelectedDate] = useState('');
  const [roundeDriverBeta, setRoundeDriverBeta] = useState(400);
  const [selectedReturnDate, setSelectedReturnDate] = useState(null);
  const [carList, setCarList] = useState(false);
  const [driverbeta, setDriverBeta] = useState(400);
  const [listCars, setListCars] = useState({ car_amount: 0 });
  const [carReq, setCarReq] = useState(false);
  const [distanceResult, setDistanceResult] = useState({ org_distance: null, org_duration: null });
  const [google, setGoogle] = useState(null);
  const [popup, setPopup] = useState(false);
  const [date,setDate ]= useState('');
  const [popupDis, setPopupDis] = useState(0);
  const [popupDur, setPopupDur] = useState('');
  const [whatsaappMsg, setWhatsaappMsg] = useState('');

  const [popupCar, setPopupCar] = useState('');
  const [popupRate, setPopupRate] = useState(0);
  const [totRate, setTotalRate] = useState(0);
  const [loadingButton, setBoadingButton] = useState(true);


  function openCars() {
    setCarList(!carList);
  }
    const today = new Date().toISOString().split('T')[0];

  const searchBox = useRef(null);
      const formRef = useRef(null);
      function sendWhatsapp() {
        setPopup(false);

        window.open("https://wa.me/+918248944500?text=" + whatsaappMsg);
      
    }


  function handlePlacesChanged(){
    const places = searchBox.current.getPlaces();
    console.log(places);

  }
  function popUpClose() {
    setPopup(false);
}
 const changeDate = (e)=>{
   setDate(e.target.value);
 };
  useEffect(() => {
    if (window.google) {
        setGoogle(window.google);
    }
}, []);

  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const onSubmit = async (data) => {
    alert('Form Submitted');
    console.log(data);
    setCarReq(false);
    if (listCars.car_amount === "0") {
        setCarReq(true);
    }
    if (listCars.car_amount !== "0" && 
    data.dropoffaddress !== '' && 
    data.fname !== '' && 
    data.phone !== '' && 
    data.pickupaddress !== '' && 
    data.pickup_date !== '' && 
     data.pickup_time !== '' &&
     data.date !== ''  
    ) {
      setBoadingButton(true);
      if (data.dropoffaddress === data.pickupaddress) {
          return alert("Please select Different Places");
      }
      if (!google) {
          alert('Google Maps API is not loaded yet.');
          return;
      }
      setPopupCar(listCars.text);

      const distanceMatrixService = new google.maps.DistanceMatrixService();

      const distanceMatrixOptions = {
          origins: [data.pickupaddress],
          destinations: [data.dropoffaddress],
          travelMode: 'DRIVING'
      };
      await distanceMatrixService.getDistanceMatrix
      (distanceMatrixOptions, (response, status) => {
        if (
          status === 'OK' 
          && response.destinationAddresses[0].length !== 0 && response.originAddresses[0].length !== 0) {
            if (response.rows[0].elements[0].distance.text || response.rows[0].elements[0].duration.text) {
                var org_distance = response.rows[0].elements[0].distance.text;
                var org_duration = response.rows[0].elements[0].duration.text;
                setPopupDur(org_duration);
                setPopupDis(org_distance);
                setDistanceResult({ org_distance, org_duration });

                if (org_distance != null && org_duration != null && data.date && data.returndate) {
           
                  const [year, month, day] = data.date.split('-');
                  const [returnYear, returnMonth, returnDay] = data.returndate.split('-');
                  // console.log('date',returnYear);
                  // console.log('date',returnMonth);
                  // console.log('date',returnDay);
                  const pickupDate = new Date(year, month - 1, day);
                  const returnDate = new Date(returnYear, returnMonth - 1, returnDay);
    
                  const timeDiff = Math.abs(returnDate.getTime() - pickupDate.getTime());
                  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
                  var newdiffDays = diffDays + 1;
                  if (newdiffDays === 1) {
                    var DriverBetaMul = newdiffDays * roundeDriverBeta;
                    setDriverBeta(DriverBetaMul);

                    var one_way_rate = parseInt(org_distance) * parseInt(listCars.car_amount);
                    setTotalRate(parseInt(org_distance) * parseInt(listCars.car_amount));
                    setPopupRate((parseInt(one_way_rate)) + DriverBetaMul)
                    if (parseInt(org_distance) <= 250) {
                        one_way_rate = 250 * parseInt(listCars.car_amount);
                        setTotalRate(250 * parseInt(listCars.car_amount));
                        setPopupRate((parseInt(one_way_rate)) + DriverBetaMul)
                    }
                    var new_tot_trip_fair = parseInt(org_distance) <= 250 
                    ? parseInt(one_way_rate) + DriverBetaMul 
                    : parseInt(one_way_rate) + DriverBetaMul;
                    var new_rate_round_trip = parseInt(org_distance) <= 250 ? one_way_rate : one_way_rate;
                } else {
                    var DriverBetaMul = newdiffDays * roundeDriverBeta;
                    setDriverBeta(DriverBetaMul);

                    var one_way_rate = ((250 * newdiffDays) * parseInt(listCars.car_amount));
                    setTotalRate(250 * parseInt(listCars.car_amount) * newdiffDays);
                    setPopupRate((parseInt(one_way_rate) + DriverBetaMul))
                    var new_rate_round_trip = one_way_rate;
                    var new_tot_trip_fair = parseInt(one_way_rate) + DriverBetaMul;
                }
        var front_url = "https://www.a2zonewaytaxi.in/";

        setWhatsaappMsg(`Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : ${data.fname},%0A%0A Mobile Number  : +91" ${data.phone},%0A%0APickup Location  : ${data.pickupaddress},%0A%0ADrop Location : ${data.dropoffaddress} ,%0A%0ATrip Type: Round Trip ,%0A%0APickup Date/Time:  ${data.date} + " " + ${data.dropdown} + ,%0A%0APickup Date/Time: ${data.returndate},%0A%0ATotal KM : ${org_distance} ,%0A%0ADuration : ${org_duration},%0A%0ARate Per KM:${listCars.car_amount}₹,%0A%0ADriver Beta :${DriverBetaMul} ₹ ,%0A%0ARate for Round Trip :  ${new_rate_round_trip}₹, %0A%0ATotal Trip Fare :${new_tot_trip_fair}₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +918248944500 %0A%0A ${front_url} `)
        console.log('WhatsApp message:', whatsaappMsg);

        var message = `Website Enquiry\n**************\n\n Your Booking Details:\n\n Name:${data.fname} \n\n Mobile Number: +91 ${data.phone} \n\n Pick Up Address: ${data.pickupaddress} \n\n Drop Off Address:${data.dropoffaddress} \n\n Trip Type: Round Way  \n\n Pick Up Time:${data.date} \n\n Pick Up Time:${data.dropdown} \n\nReturn Date :  ${data.returndate} , \n\nTotal KM : ${org_distance} ,\n\nDuration :  ${org_duration}  ,\n\nRate Per KM:  ${listCars.car_amount}₹ ,\n\nDriver Beta : ${DriverBetaMul}₹  ,\n\nRate for Round Trip :${new_rate_round_trip}₹,\n\nTotal Trip Fare : ${new_tot_trip_fair}₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +918248944500 \n\n ${front_url} `;

try{
    const response = axios.post(
      `https://api.telegram.org/bot6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo/sendMessage`,
      {
        chat_id: 1113684027,
        text: message,
                 } );
                 console.log(response);
                if(response)
                 {
                  setListCars({
                                    text: '',
                                    imageSrc: '',
                                    car_amount: 0
                                })
                                setBoadingButton(false);
                                formRef.current.reset();
                                setPopup(true);
                  } }
                              
                              catch (error) {
                                setBoadingButton(false);
                                alert('Please try again')
                                console.error('Error sending Telegram message:', error);
                            }
                            // try{
                            //   const response = axios.post(
                            //     `https://api.telegram.org/bot6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo/sendMessage`,
                            //     {
                            //       chat_id: 1113684027,
                            //       text: message,
                            //                } );
                            //               }
                            //               catch (error) {
                            //                 console.error('Error sending Telegram message:', error);
                            //             }
                                      }
            
  else {

    alert("Please Try again!");
}
}
      }
else {
  console.error('Error:', status);
}

});
    
  };}
  const selectCar = (text, imageSrc, car_amount, carDriverBeta) => {
    setListCars({
      text,
      imageSrc,
      car_amount
    });
    setRoundeDriverBeta(carDriverBeta)
    setCarList(false);
  };
  return (
    <div className='container-oneway'>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>


        <div class="form-header">
          <div class="input-fname">
            <input type="text" placeholder='First Name'
              className='form-input' name="fname" {...register("fname", { required: "First Name is required" })} />
            {errors?.fname && <span className='form-error'>{errors.fname.message}</span>}</div>
          <div class="input-fname">
            <input type="int" placeholder='Mobile Number' name="phone"
              className='form-input' {...register("phone", { required: "Mobile Number is required"  ,minLength:{value:10,message:"Requied 10 digit"}})} />
            {errors?.phone && <span className='form-error'>{errors.phone.message}</span>}</div>
          <div class="input-pickupaddress">
          <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >

            <input type="text" placeholder='Pick Up Address' name="pickupaddress"
              className='form-input' {...register("pickupaddress", { required: "Pick Up Address" })} />
                            </ StandaloneSearchBox>

            {errors?.pickupaddress && <span className='form-error'>{errors.pickupaddress.message}</span>}</div>
          <div class="input-pickupaddress">
          <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >

            <input type="text" placeholder='Drop Off Address' name="dropoffaddress"
              className='form-input' {...register("dropoffaddress", { required: "Drop Off Address" })} />
              </ StandaloneSearchBox>
           
            {errors?.dropoffaddress && <span className='form-error'>{errors.dropoffaddress.message}</span>}</div>
            <div class="input-date">
            <input type="date"  name="date"
              className='form-input'
              min={today}
              placeholder="Pick Up Date"
               {...register("date", { required: " date" })} />
            {errors?.date && <span className='form-error'>{errors.date.message}</span>}
</div>
           {/* <div class="input-date">
            <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              className='form-input'
              placeholderText="Pick up Date"
              name='pickup_date' />
            
            <input type="hidden" value={date}   name='pickup_date'
              {...register("pickup_date", { required: "Required Date" })} />
             {!selectedDate && errors?.pickup_date && <span className='form-error'>{errors.pickup_date.message}</span>}
          </div>  */}
          <div class="input-dropdown ">
            <select id="pick_up_time " className='form-input'
              name="dropdown" {...register("dropdown", { required: "PickUp Time is required" })}>
              <option value="">Pick Up Time</option>
              <option value="12.00 AM">12.00 AM</option>
              <option value="12.30 AM">12.30 AM</option>
              <option value="1.00 AM">1.00 AM</option>
              <option value="1.30 AM">1.30 AM</option>
              <option value="2.00 AM">2.00 AM</option>
              <option value="2.30 AM">2.30 AM</option>
              <option value="3.00 AM">3.00 AM</option>
              <option value="3.30 AM">3.30 AM</option>
              <option value="4.00 AM">4.00 AM</option>
              <option value="4.30 AM">4.30 AM</option>
              <option value="5.00 AM">5.00 AM</option>
              <option value="5.30 AM">5.30 AM</option>
              <option value="6.00 AM">6.00 AM</option>
              <option value="6.30 AM">6.30 AM</option>
              <option value="7.00 AM">7.00 AM</option>
              <option value="7.30 AM">7.30 AM</option>
              <option value="8.00 AM">8.00 AM</option>
              <option value="8.30 AM">8.30 AM</option>
              <option value="9.00 AM">9.00 AM</option>
              <option value="9.30 AM">9.30 AM</option>
              <option value="10.00 AM"> 10.00 AM</option>
              <option value="10.30 AM">10.30 AM</option>
              <option value="11.00 AM">11.00 AM</option>
              <option value="11.30 AM">11.30 AM</option>
              <option value="12.00 PM">12.00 PM</option>
              <option value="12.30 PM">12.30 PM</option>
              <option value="1.00 PM">1.00 PM</option>
              <option value="1.30 PM">1.30 PM</option>
              <option value="2.00 PM">2.00 PM</option>
              <option value="2.30 PM">2.30 PM</option>
              <option value="3.00 PM">3.00 PM</option>
              <option value="3.30 PM">3.30 PM</option>
              <option value="4.00 PM">4.00 PM</option>
              <option value="4.30 PM">4.30 PM</option>
              <option value="5.00 PM">5.00 PM</option>
              <option value="5.30 PM">5.30 PM</option>
              <option value="6.00 PM">6.00 PM</option>
              <option value="6.30 PM">6.30 PM</option>
              <option value="7.00 PM">7.00 PM</option>
              <option value="7.30 PM">7.30 PM</option>
              <option value="8.00 PM">8.00 PM</option>
              <option value="8.30 PM">8.30 PM</option>
              <option value="9.00 PM">9.00 PM</option>
              <option value="9.30 PM">9.30 PM</option>
              <option value="10.00 PM">10.00 PM</option>
              <option value="10.30 PM">10.30 PM</option>
              <option value="11.00 PM">11.00 PM</option>
              <option value="11.30 PM">11.30 PM</option>

            </select>{errors?.dropdown && <span className='form-error'>{errors.dropdown.message}</span>}
          </div>
          <div class="input-date">

          <input type="date" name="returndate"
              className='form-input'
              min={today}
              placeholder="Pick Up Date"
              {...register("returndate", { required: " returndate" })} />
            {errors?.returndate && <span className='form-error'>{errors.returndate.message}</span>}
        </div>
        </div>
        <div>
          <input type="hidden" name='car_amount' value={listCars.car_amount !== 0 ? listCars.car_amount : 0} />
          <div className='input-selectButton' onClick={openCars}>
            {listCars.text ? listCars.text : 'Select car Type'}

            <img src={listCars.imageSrc || select_Car} className="select_car_image" alt="car" />
          </div>
          <ul className={`input-ul  ${carList ? '' : 'input-ul-hidden'}`}>
            <li className='input-ul-li' onClick={() => selectCar('SEDAN', seden, 13, 400)}>SEDAN
              <img src={seden} alt="etios" className='input-ul-li-img' /></li>
            < li className='input-ul-li' onClick={() => selectCar('SUV', suv, 17, 400)} >SUV
              <img src={suv} alt="suv" className='input-ul-li-img' /></li>
              < li className='input-ul-li' onClick={() => selectCar(' ASSURED  INNOVA', innova, 18, 400)} >ASSURED  INNOVA
              <img src={innova} alt="innova" className='input-ul-li-img' /></li>
              < li className='input-ul-li' onClick={() => selectCar(' INNOVA CRYSTA', innova, 21, 400)} >INNOVA CRYSTA
              <img src={innova} alt="innova" className='input-ul-li-img' /></li>

          </ul>
          {carReq ? <span className='form-error'>Car is required.</span> : ''}

        </div>
        <div className="form-button">
          <input type='submit' value='GET ESTIMATION' className='form-submitbutton' />
        </div>
      </form>
    {popup ?  <div class="popup-container">
    <div className="popup">
      
    <span className>
            <FaTimes onClick={popUpClose} className='popupclose' />
          </span>
          <h2 className='popuph2'>Trip Estimation:<span className='popuprate'>Rs.{popupRate}</span></h2>
          <div>
            <div className='detailspopup'>
              <div className="detaispopup-left">Total Distance:</div>
              <div className="detaispopup-right">
                {popupDis}
              </div>
              <div className="detaispopup-left">Total Duration:</div>
              <div className="detaispopup-right">
                {popupDur}
              </div>
              <div className="detaispopup-left">Selected Car Type:</div>
              <div className="detaispopup-right">
                {popupCar}
              </div>
              <div className="detaispopup-left">Driver Allowance:</div>
              <div className="detaispopup-right">Included</div>
              <div className="detaispopup-left">Total Awarness:</div>
              <div className="detaispopup-right">
                Rs. {totRate}
              </div>
              <div className="detaispopup-left">Driver Beta:</div>
              <div className="detaispopup-right">
                Rs. {roundeDriverBeta}
              </div>
              <div className="detaispopup-leftlast">Total Driver Beta:</div>
              <div className="detaispopup-rightlast">
                Rs. {driverbeta}
              </div>

            </div>
            <div className='popupdivbutton'>
              <button onClick={sendWhatsapp} className='popupbutton'>Book Now</button>
            </div>
          </div>   </div>  </div> : ' '}


    </div>
  );

}
export default Roundway
