import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

const Card = (props) => {
  return (
    <div className="container-card">
      <div className={props.name}>
        <img src={props.image} className={props.className}></img>
      </div>

      <div className="class-content">
     <span className=" content-icon-div"> <FontAwesomeIcon icon={faCar} className='content-icon' /></span>
        <div className='car-name'>
          <h1 className='car-name-h1'>{props.name}</h1>
           <div className='car-rs'>
            <div className='car-rs-span'>
           <span className='car-rs-span1'>Start From</span>
           <span className='car-rs-span2'>Rs.{props.rs}</span>
           </div>
           {/* <div className='car-rs-span'>
           <span className='car-rs-span1'>Round Trip</span>
           <span className='car-rs-span2'>Rs.{props.rupee}</span>
           </div> */}
           </div>
           <div className='card-linkbox'><a href="#booking" className='card-link'>BOOK NOW TAXI</a></div>
           </div>

        </div>
    </div>
  )
}

export default Card
