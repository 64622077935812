import './App.css';
import Header from './skycabs/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Footer from './skycabs/Footer/Footer';
import PhoneImage from './skycabs/PhoneImage';
import WhatsappImage from './skycabs/WhatsappImage';
import Booking from './skycabs/Booking/Booking';
import Home from './skycabs/Home';
import AboutUs from './skycabs/Booking/AboutUs';
import PivacyPolicy from './skycabs/Footer/PivacyPolicy';
import TermsAndCondition from './skycabs/Footer/TermsAndCondition';
import CommonHeader from './skycabs/CommonHeader';

function App() {
  return (
    <BrowserRouter>
    <CommonHeader />

    <Routes>
          <Route path="/" element={<Header />}></Route>
          <Route path="/privacy-policy" element={<PivacyPolicy />}></Route>
          { <Route
            path="/terms-and-conditions"
            element={<TermsAndCondition/>}
          ></Route> }


    </Routes>
    <Footer />
    <PhoneImage />
    <WhatsappImage />
    </BrowserRouter>

 );
}

export default App;
