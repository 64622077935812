import React from 'react'

const Outstation = () => {
  return (
    <div class="footer-outstation">
        <h3>Outstation Service</h3>
      <ul class="footer-outstation-ul">
        <li>One Way</li>
        <li>Round Trip</li>
        <li>Round Trip</li>
        <li>Drop Taxi</li>
        <li>Airport Taxi</li>
        <li>Rental Taxi</li>
      </ul>
    </div>
  )
}

export default Outstation
