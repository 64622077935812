import React from 'react'

const Chennai = (props) => {
  return (
    <div>
    <h3  id="footer-h3">Chennai</h3>
    <div >
<p id='footer-para'>Chennai to Coimbatore taxi</p>
<p id='footer-para'>Chennai to Bangalore taxi</p>
<p id='footer-para'>Chennai to Kochi taxi</p>
<p id='footer-para'>Chennai to Madurai taxi</p>
<p id='footer-para'>Chennai to Salem taxi</p>
<p id='footer-para'>Chennai to Trichy taxi</p>
<p id='footer-para'>Chennai to Pondicherry taxi</p>
<p id='footer-para'>Chennai to Tirunelveli taxi</p>
<p id='footer-para'>Chennai to Neyveli taxi</p>
<p id='footer-para'>Chennai to Rameshwaram taxi</p>
</div >
    </div>
  )
}

export default Chennai
