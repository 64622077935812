import React from 'react'

const Officialinfo = () => {
  return (
    <div className="footer-official-info">
    <div className='footer-official-info-location'>
      <h5>Location:</h5>
      <span> Coimbatore, Tamil Nadu, India, 641104
      </span>
    </div>
        <div className='footer-official-info-location'>
        <h5>Email</h5>
        <span>a2onewaytaxi1@gmail.com        </span>
      </div>
    <div className='footer-official-info-location'>
    <h5>Phone:</h5>
    <span>8248944500</span>
  </div>
  </div>
  )
}

export default Officialinfo
