import React from 'react'
import VehicleImage from './VehicleImage'
import Places from '../Places'
import place from '../assert/place1.jpeg'
import salem from '../assert/salem.jpeg'
import Trichy from '../assert/trichy.jpeg'
import pondicherry from '../assert/pondy.jpeg'
import banglore from '../assert/bangalore.jpeg'
import madurai from '../assert/madurai.jpg'
import thiruvannamalai from '../assert/thiruvannamalai.jpeg'
import tirupathi from '../assert/tirupathi.jpeg'
import ooty from '../assert/ooty.jpeg'
import chennai from '../assert/chennai.jpeg'
import dharmapuri from '../assert/dharmapuri.jpeg'
import pollachi from '../assert/pollachi.jpeg'
import car1 from   '../assert/vehicle1.png'
import car2 from '../assert/vehicle2.jpg'
import car3 from '../assert/vehicle3.png'

const Vehicle = () => {
  return (
    <div className="container-vehile" id="vehicle">
       <div className='vehicle-header'>
        <h1 className="vehicle-h1">Our vehicle</h1>
        <h1>RIDE WITH EXPERTS</h1>
         <div className="container-vehicleImage">
          <VehicleImage image={car1} className={"vehicle-image1"} carName="SWIFT DZIRE" companyname="SEDAN"/>
          <VehicleImage image={car2} className={"vehicle-image2"} carName="TOYOTO INNOVA" companyname="PRIME SUV"/>
          <VehicleImage image={car3} className={"vehicle-image3"} carName="MAHENDRA XYLO" companyname="SUV"/>

         </div>
       </div>
      <div className='vehicle-routes'>
        <h2>Top Drop Taxi Routes</h2>
        <span>Book Online Outstation Taxi Service in and Around Tamil Nadu, Pondicherry and Bangalore. One Way 
            <br />Drop Taxi @ One Way Fare.</span>
<div className='container-places'>
  <div><Places image={place} place="Chennai to Coimbatore Taxi" rupees="2545"/></div>
  <div><Places image={salem} place="Chennai to Salem Taxi" rupees="4885"/></div>
  <div><Places image={Trichy} place="Chennai to Trichy Taxi" rupees="4713"/></div>
  <div><Places  image={pondicherry} place="Chennai to Pondicherry Taxi" rupees="2545"/></div>
  <div><Places  image={banglore} place="Chennai to Bangalore Taxi" rupees="4916"/></div>
  <div><Places  image={madurai} place="Chennai to Madurai Taxi" rupees="6410"/></div>
  <div><Places  image={thiruvannamalai} place="Chennai to Tiruvannamalai Taxi" rupees="2922"/></div>
  <div><Places  image={tirupathi} place="Chennai to Tirupati Taxi" rupees="2129"/></div>
  <div><Places  image={ooty} place="Coimbatore to Ooty Taxi" rupees="1512"/></div>
  <div><Places  image={chennai} place="Coimbatore to Ooty Taxi" rupees="4694"/></div>
  <div><Places  image={dharmapuri} place="Chennai to Dharmapuri Taxi" rupees="4305"/></div>
  <div><Places image={pollachi} place="Chennai to Pollachi Taxi" rupees="7260"/></div>
  </div>      
</div>
    </div>
  )
}

export default Vehicle
