import React from 'react'
import AboutGif from '../../assert/aboutgif..gif'

const AboutUs = () => {
  return (
    <div className='Conatiner-aboutus' id="aboutus_sec">
      <div class="aboutus-gif">
      <div className='aboutus-image'>
        <img src={AboutGif} className="aboutus-imagetag" />
        </div>
        <div className='abotus-content'>
        <h6 className='abotus-content-h6'>ABOUT US</h6>
        <h1 className='abotus-content-h1'>A2 ONE WAY TAXI</h1>
        <p className='abotus-content-para'>When it comes to taxi services in South India, A2 One Way Taxi is among the best. We value your time, safety, and comfort above anything else. We offer car hire services with seating capacity ranging from 4 to 40 passengers. Not only this, but we also provide both one way outstation cabs as well as round trip taxi rental options. You can also book a cab for hourly rental, airport transfers, corporate travel, employee transportation, weekend, honeymoon package tours and much more. Rapidly call for a taxi service.

</p>
          <button className='aboutus-button'><a href='#booking'>BOOK NOW </a></button>
          </div>
      </div>
    </div>
  )
}

export default AboutUs
