import React from 'react'

const VehicleImage = (props) => {
  return (
    <div>
             <div className="vehicle-image">
            <img src={props.image} className={props.className} />
            <div className='vehicle-name'> 
              <p className='vehicle-name-p'>{props.carName}</p>
              <span className='vehicle-name-span'>{props.companyname}</span>
            </div>
       </div>

    </div>
  )
}

export default VehicleImage
