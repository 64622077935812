import React from 'react'

const Kanyakumari = () => {
  return (
    <div>
    <h3 id="footer-h3">Kanyakumari</h3>
    <div>
<p id='footer-para'>Kanyakumari to Chennai taxi</p>
<p id='footer-para'>Kanyakumari to Bangalore taxi</p>
<p id='footer-para'>Kanyakumari to Thoothukudi taxi</p>
<p id='footer-para'>Kanyakumari to Madurai taxi</p>
<p id='footer-para'>Kanyakumari to Salem taxi</p>
<p id='footer-para'>Kanyakumari to Trichy taxi</p>
<p id='footer-para'>Kanyakumari to Coimbatore taxi</p>
<p id='footer-para'>Kanyakumari to Pondicherry taxi</p>
<p id='footer-para'>Kanyakumari to Neyveli taxi</p>
<p id='footer-para'>Kanyakumari to Rameshwaram taxi</p>
</div>
    </div>
   )
}

export default Kanyakumari
