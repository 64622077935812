import React from 'react'

const Madurai = () => {
  return (
    <div>
    <h3 id="footer-h3">Madurai</h3>
    <div>
<p id='footer-para'>Madurai to Chennai taxi</p>
<p id='footer-para'>Madurai to Bangalore taxi</p>
<p id='footer-para'>Madurai to Thoothukudi taxi</p>
<p id='footer-para'>Madurai to Coimbatore taxi</p>
<p id='footer-para'>Madurai to Salem taxi</p>
<p id='footer-para'>Madurai to Trichy taxi</p>
<p id='footer-para'>Madurai to Pondicherry taxi</p>
<p id='footer-para'>Madurai to Tirunelveli taxi</p>
<p id='footer-para'>Madurai to Neyveli taxi</p>
<p id='footer-para'>Madurai to Rameshwaram taxi</p>
</div>
    </div>
  )
}

export default Madurai
