import React from 'react'
import '../../App.css'
import SwitchOneWayRoundway from './SwitchOneWayRoundway'
const Booking = () => {
    return (
        <div className="container-booking" id="booking">
                <h1>Dream Explore Discover</h1>
                <p>One of the most affordable online taxi booking service</p>
                <p className="booking-location-p">Location: Chennai, Coimbatore, Madurai, Pondicherry, Bangalore and more</p>
            <div><SwitchOneWayRoundway /></div>
        </div>
    )
}

export default Booking
