import React, { Fragment, useEffect } from 'react'

const PivacyPolicy = () => {
    useEffect(() => {

        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className='container-privacy'>
        <div className='privacy-header'>
            <h2>Privacy Policy</h2>
            <span className='privacy-span'>
            <a href='/' className='linkhome'>Home</a>
            <a href='#'className='linkprivacy'>/Privacy Policy</a></span>
        </div>
        <div className='privacy-policy-content'>
               <div className='privacy-policy-content-div'>
                   <h2>Privacy Policy</h2>
                   <p>This privacy policy sets out how  <a href=''>a2zonewaytaxi.in</a> collects, uses, maintains and discloses information collected from users (each, a “User”) of the website. This privacy policy applies to all services offered by A2 One Way Taxi.

</p>
               </div>
               <div className='privacy-policy-content-div'>
                  <h2>Use of collected information</h2>
                  <p>Information Collection and Use A2 One Way Taxi is the sole owner of the information collected on this website. A2 One Way Taxi only has access to and collects information that Users voluntarily give via the website This information may include but is not limited to name, phone number, email address, and location data.</p>
               </div>
               <div className='privacy-policy-content-div'> 
                <h2>Use of collected information</h2>
                <p>A2 One Way Taxi uses collected information for the following purposes:</p>
                <ul className='privacy-policy-content-div-ul'>
                    <li>To provide the services</li>
                    <li>To improve customer service</li>
                    <li>To personalize user experience</li>
                    <li>To send periodic emails</li>
                </ul>
               </div>
               <div className='privacy-policy-content-div'>
                <h2>Confidentiality and Security</h2>
                <p>A2 One Way Taxi is committed to ensuring the security of users’ information. A2 One Way Taxi implements a variety of security measures to maintain the safety of users’ information when Users access the website and perform transactions.</p>
               </div>
               <div className='privacy-policy-content-div'>
                <h2>Disclosure of Information to Third Parties</h2>
                <p>A2 One Way Taxi does not sell, trade, or rent User’s personal identification information to others. A2 One Way Taxi may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with its business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
               </div>
               <div className='privacy-policy-content-div'>
                <h2>Cookies</h2>
                <p>A2 One Way Taxi uses "cookies" to enhance the User experience. Users’ web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or to alert them when cookies are being sent.</p>
               </div>
               <div className='privacy-policy-content-div'>
                <h2>Changes to this Privacy Policy</h2>
                <p>A2 One Way Taxi has the discretion to update this privacy policy at any time. When A2 One Way Taxi does, Users will be notified via the website or by email. Users acknowledge and agree that it is their responsibility to review this privacy policy periodically and become aware of modifications.</p>
               </div>
               <div className='privacy-policy-content-div'>
                 <h2>Your Acceptance of these Terms</h2>
                 <p>By using this website, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our website. Your continued use of the website following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
               </div>
               <div className='privacy-policy-content-div'>
                <h2>Contacting Us</h2>
                <p>If you have any questions about this Privacy Policy, the practices of this website, or your dealings with this website, please contact us at a2onewaytaxi1@gmail.com</p>
               </div>
        </div>
    </div>
  )
}

export default PivacyPolicy
